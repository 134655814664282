import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// Components
import Thumb from '../Thumb';
import Rate from '../Rate';

// Config
import { IMAGE_BASE_URL, POSTER_SIZE } from '../../config';

// Image
import NoImage from '../../images/no_image.jpg';

// Styles
import { Wrapper, Content, Text } from './MovieInfo.styles';

// Context
import { Context } from '../../context';

const MovieInfo = ({ movie }) => {
  const [user] = useContext(Context);

  return (
    <Wrapper backdrop={movie.backdrop_path}>
      <Content>
        <Thumb
          image={
            movie.poster_path
              ? `${IMAGE_BASE_URL}${POSTER_SIZE}${movie.poster_path}`
              : NoImage
          }
          clickable={false}
          alt="movie-thumb"
        />
        <Text>
          <h1>{movie.title}</h1>

          <h3>PLOT</h3>

          <p>{movie.overview}</p>

          <div className="rating-directors">
            <div>
              <h3>RATING</h3>

              <div className="score">{movie.vote_average}</div>
            </div>

            <div className="directors">
              <h3>DIRECTOR{movie.directors.length > 1 ? 'S' : ''}</h3>
              {movie.directors.map((director) => (
                <p key={director.credit_id}>{director.name}</p>
              ))}
            </div>
          </div>
          {user ? (
            <div className="rate">
              <p>Rate the movie</p>
              <Rate />
            </div>
          ) : (
            <Link to="/login">Login to leave a rating!</Link>
          )}
        </Text>
      </Content>
    </Wrapper>
  );
};

MovieInfo.propTypes = {
  movie: PropTypes.object,
};

export default MovieInfo;
